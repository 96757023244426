(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/retail-list/assets/javascripts/retail-list.js') >= 0) return;  svs.modules.push('/components/marketplace/retail-list/assets/javascripts/retail-list.js');

'use strict';

const {
  useSelector
} = ReactRedux;
const {
  GroupList
} = svs.components.marketplace.groupList;
const {
  RetailListItem
} = svs.components.marketplace.retailList;
const {
  helpers
} = svs.components.marketplace;
const {
  useHistory
} = ReactRouterDOM;
const {
  getRetailers
} = svs.components.marketplaceData.selectors.teams;
const {
  selectChatRooms
} = svs.components.marketplaceData.selectors.chat;
const RetailList = _ref => {
  let {
    isBrowseGames,
    children,
    renderOnEmpty,
    teamClassName,
    teamLinkSearch,
    maxGamesToDisplay,
    offsetForLastClicked,
    shouldUseLastClicked
  } = _ref;
  const history = useHistory();
  const retailers = useSelector(state => getRetailers(state, isBrowseGames));
  const chatRooms = useSelector(state => selectChatRooms(state));
  const setLastClicked = _ref2 => {
    let {
      id: teamId
    } = _ref2;
    if (shouldUseLastClicked) {
      svs.components.Storage.browser.set('lastClickedTeam', 'marketplace', {
        groupId: teamId,
        offset: offsetForLastClicked
      });
    }
  };
  return React.createElement(GroupList, {
    listData: retailers,
    renderItem: (team, key) => React.createElement(RetailListItem, {
      className: teamClassName,
      history: history,
      isHeaderInverted: true,
      key: team.id || key,
      linkTo: {
        pathname: helpers.getRetailUrl(team.id, team.name),
        search: teamLinkSearch
      },
      maxGamesToDisplay: maxGamesToDisplay,
      onClick: () => setLastClicked(team),
      team: team,
      unreadMessages: (chatRooms["marketplace_".concat(team.id)] || {}).unread
    }, children(team)),
    renderOnEmpty: renderOnEmpty
  });
};
RetailList.defaultProps = {
  children: () => {}
};
setGlobal('svs.components.marketplace.retailList.RetailList', RetailList);

 })(window);